import moment from "moment";
import ApiService from "../services/ApiService";
interface ProductionFusing {
  id: number;
  qty: string;
  reQty: string;
  waste: string;
  status: string;
  dateIn: Date;
  dateOut: Date;
  remark: string;
  design_id: string;
  design_data: any;
  production_id: string;
  production_data: any;
  created_by: string;
}

const CancelToken = ApiService.getInstance().CancelToken;
let cancelRequest;
const ProductionFusings: Array<ProductionFusing> = [];

const deleteProductionFusing = function (item: ProductionFusing) {
  return ApiService.delete(`production-fusing/${item.id}/`);
};

const addProductionFusing = function (item, id) {
  const formData = new FormData();
  delete item.created_by
  const dateFieldKey: any = [
    "dueDate",
    "dateIn",
    "dateOut",
    "cuttingDateIn",
    "cuttingDateOut",
    "peelingDateIn",
    "peelingDateOut",
    "fusingDateIn",
    "fusingDateOut",
  ];
  for (const key in item) {
    if (dateFieldKey.includes(key)) {
      formData.append(key, moment(item[key]).format("YYYY-MM-DD"));
    } else {
      formData.append(key, item[key]);
    }
  }
  if (id) {
    return ApiService.put(`production-fusing/${id}/`, formData);
  } else {
    return ApiService.post("production-fusing/", formData);
  }
};

const exportProductionFusingData = function (ids) {
  return new Promise((res, rej) => {
    ApiService.csvFile(
      "export/production-fusing/?production=" + ids,
      "productionFusing-data"
    ).then((data) => res(data));
  });
};
const exportPDFProductionFusings = function (ids) {
  return new Promise((res, rej) => {
    ApiService.pdfFile("pdf/production-fusing/?production=" + ids).then((data) =>
      res(data)
    );
  });
};
const productionFusingObjClone = function () {
  return {
    id: 0,
    reQty: "",
    qty: "",
    waste: "",
    status: "",
    dateIn: new Date(),
    dateOut: new Date(),
    remark: "",
    design_id: "",
    design_data: {},
    production_id: "",
    production_data: {},
    created_by: "",
  };
};
const jsonToInterface = function (item: any) {
  const newItem: ProductionFusing = productionFusingObjClone();
  newItem.id = item.id;
  newItem.reQty = item.reQty;
  newItem.qty = item.qty;
  newItem.waste = item.waste;
  newItem.status = item.status;
  newItem.dateOut = moment(item.dateOut).toDate();
  newItem.dateIn = moment(item.dateIn).toDate();
  newItem.remark = item.remark;
  newItem.design_id = item.design_id;
  newItem.design_data = item.design_data;
  newItem.production_id = item.production_id;
  newItem.production_data = item.production_data;
  newItem.created_by = item.created_by;
  return newItem;
};
const getProductionFusingById = function (id) {
  return new Promise((res, rej) => {
    ApiService.get(`production-fusing/${id}`).then(function (response) {
      res(jsonToInterface(response.data));
    });
  });
};

const getProductionFusings = function (
  pageNumber = 1,
  searchData,
  status = "",
  production_id = "",
  design_id = "",
) {
  return new Promise((res, rej) => {
    const limit = 10;
    const queryObj = {
      page: pageNumber,
      limit: limit,
      offset: (pageNumber - 1) * limit,
      search: searchData.name || "",
      status: status,
      production_id: production_id,
      design_id: design_id,
    };
    console.log(queryObj);
    const queryStr = Object.keys(queryObj)
      .filter((key) => queryObj[key] != "")
      .map((key) => key + "=" + queryObj[key])
      .join("&");

    if (typeof cancelRequest != typeof undefined) {
      cancelRequest.cancel("Operation canceled due to new request.");
    }
    cancelRequest = CancelToken.source();
    ApiService.getWithoutSlash(`production-fusing/?${queryStr}`, cancelRequest)
      .then(function (response) {
        const resObj: any = {
          count: 0,
          data: [],
        };
        resObj.count = response.data.count;
        if (response.data && response.data.results) {
          resObj.data = response.data.results.map((item) => {
            return jsonToInterface(item);
          });
        }
        console.log("resObj", resObj);
        res(resObj);
      })
      .catch((error) => rej(error));
  });
};

const jsonToInterfaceProductionFusing = jsonToInterface;
const getEmptyObjOfProductionFusing = productionFusingObjClone;
export {
  ProductionFusing,
  getProductionFusings,
  deleteProductionFusing,
  addProductionFusing,
  getProductionFusingById,
  jsonToInterfaceProductionFusing,
  productionFusingObjClone,
  exportProductionFusingData,
  exportPDFProductionFusings,
  getEmptyObjOfProductionFusing
};

export default ProductionFusings;
